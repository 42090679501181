<template>
<div class="header-title-wrap">
	<div class="icon">
		<RegisterIcon v-if="icon === 'RegisterIcon'" class="svg-icon svg-icon-register" />
	</div><!-- icon -->
	<div class="text">
		<span class="subtitle">{{ subtitle }}</span>
		<span class="title">{{ title }}</span>
	</div><!-- text -->
</div><!-- header title wrap -->
</template>

<script>
import RegisterIcon from '@/assets/register.svg';
export default {
name: 'HeaderTitleWrap',
	props: {
		subtitle: String,
		title: String,
		icon: String
	},
	components: {
		RegisterIcon
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.header-title-wrap {
		margin: 0 28px;
		padding-top: 40px;
		width: 100%;
		display: flex;
		align-items: center;
		.icon {
			width: 40px;
			height: 40px;
			border-radius: 100%;
			background: $green_dark;
			position: relative;
			display: flex;
			justify-items: center;
			align-items: center;
			flex-direction: column;
			margin-right: 15px;
			flex-shrink: 0;
			svg {
    			flex: auto;
			}
		}
		.svg-icon-register {
			width: 20px;
			g {
				stroke-width: 3;
			}
		}
		.text {
			text-align: left;
			span {
				display: block;
				font-size: 17px;
				letter-spacing: -0.5px;
				color: $white;
			}
			.title {
				font-weight: $weight_600;
			}
			.subtitle {
				opacity: 0.8;
			}
		}
	}
</style>
